import Link from 'next/link';
import React, { ReactNode } from 'react';
import { Spinner } from '../Spinner';
import styled from 'styled-components';
import { UrlObject } from 'url';
import Icon, { IconNames } from '../Icons';

declare type Url = string | UrlObject;

type ButtonSize = 'small' | 'medium' | 'large';
type ButtonVariant = 'fill' | 'outline' | 'ghost' | 'ghost-white';

const SIZES = {
  small: {
    '--padding': '0 16px',
    '--font-size': 'var(--font-size-button-03)',
    '--height': '32px',
    '--box-shadow': 'var(--shadow-button-small)',
  },
  medium: {
    '--padding': '0 24px',
    '--font-size': 'var(--font-size-button-02)',
    '--height': '42px',
    '--box-shadow': 'var(--shadow-button-medium)',
  },
  large: {
    '--padding': '0 36px',
    '--font-size': 'var(--font-size-button-01)',
    '--height': '50px',
    '--box-shadow': 'var(--shadow-button-large)',
  },
};

const ICON_SIZES = {
  small: '14px',
  medium: '20px',
  large: '22px',
};

interface ButtonSizes {
  default: ButtonSize;
  laptop?: ButtonSize;
  tablet?: ButtonSize;
  phone?: ButtonSize;
}

interface DefaultBaseProps {
  size: ButtonSize | ButtonSizes;
  variant: ButtonVariant;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  dataTest?: string;
  isLoading?: boolean;
  children: ReactNode | ReactNode[];
  onMouseEnter?: () => void;
}

interface BaseProps extends DefaultBaseProps {
  withIcon?: never;
  iconId?: never;
  iconLeft?: never;
}

interface ButtonProps {
  link?: false;
  href?: never;
  onClick?: () => void;
}

interface LinkProps {
  link: true;
  href: Url;
  onClick?: never;
}

type DefaultProps = BaseProps & (ButtonProps | LinkProps);

interface WithIconBaseProps extends DefaultBaseProps {
  withIcon: true;
  iconId: IconNames;
  iconLeft?: boolean;
}

type WithIconProps = WithIconBaseProps & (ButtonProps | LinkProps);

type Props = DefaultProps | WithIconProps;

const Button = ({
  size,
  variant,
  disabled,
  fullWidth,
  type,
  onClick,
  link,
  href,
  withIcon,
  iconId,
  dataTest,
  children,
  iconLeft,
  isLoading,
  onMouseEnter,
}: Props) => {
  const sizeStyles =
    typeof size === 'string'
      ? SIZES[size]
      : {
          ...SIZES[size.default],
          '--laptop-padding': size?.laptop
            ? SIZES[size.laptop]['--padding']
            : undefined,
          '--laptop-font-size': size?.laptop
            ? SIZES[size.laptop]['--font-size']
            : undefined,
          '--laptop-height': size?.laptop
            ? SIZES[size.laptop]['--height']
            : undefined,
          '--laptop-box-shadow': size?.laptop
            ? SIZES[size.laptop]['--box-shadow']
            : undefined,
          '--tablet-padding': size?.tablet
            ? SIZES[size.tablet]['--padding']
            : undefined,
          '--tablet-font-size': size?.tablet
            ? SIZES[size.tablet]['--font-size']
            : undefined,
          '--tablet-height': size?.tablet
            ? SIZES[size.tablet]['--height']
            : undefined,
          '--tablet-box-shadow': size?.tablet
            ? SIZES[size.tablet]['--box-shadow']
            : undefined,
          '--phone-padding': size?.phone
            ? SIZES[size.phone]['--padding']
            : undefined,
          '--phone-font-size': size?.phone
            ? SIZES[size.phone]['--font-size']
            : undefined,
          '--phone-height': size?.phone
            ? SIZES[size.phone]['--height']
            : undefined,
          '--phone-box-shadow': size?.phone
            ? SIZES[size.phone]['--box-shadow']
            : undefined,
        };

  const iconStyles =
    typeof size === 'string'
      ? { '--icon-size': ICON_SIZES[size] }
      : {
          '--icon-size': ICON_SIZES[size.default],
          '--laptop-icon-size': size?.laptop
            ? ICON_SIZES[size.laptop]
            : undefined,
          '--tablet-icon-size': size?.tablet
            ? ICON_SIZES[size.tablet]
            : undefined,
          '--phone-icon-size': size?.phone ? ICON_SIZES[size.phone] : undefined,
        };

  let Component;
  if (variant === 'fill') {
    Component = FillButton;
  } else if (variant === 'outline') {
    Component = OutlineButton;
  } else if (variant === 'ghost') {
    Component = GhostButton;
  } else if (variant === 'ghost-white') {
    Component = GhostWhiteButton;
  } else {
    throw new Error(`Unrecognized Button variant: ${variant}`);
  }

  if (link && href) {
    return (
      <Link href={href} passHref>
        <Anchor data-test={dataTest} onMouseEnter={onMouseEnter}>
          <Component
            disabled={disabled}
            fullWidth={fullWidth}
            style={sizeStyles}
          >
            {isLoading ? (
              <Spinner size={size} />
            ) : withIcon && iconId ? (
              <ChildrenContainer style={iconStyles}>
                {iconLeft ? <Icon id={iconId} /> : null}
                {children}
                {!iconLeft ? <Icon id={iconId} /> : null}
              </ChildrenContainer>
            ) : (
              children
            )}
          </Component>
        </Anchor>
      </Link>
    );
  } else {
    return (
      <Component
        data-test={dataTest}
        type={type}
        onClick={onClick}
        fullWidth={fullWidth}
        disabled={disabled}
        style={sizeStyles}
        onMouseEnter={onMouseEnter}
      >
        {isLoading ? (
          <Spinner size={size} />
        ) : withIcon && iconId ? (
          <ChildrenContainer style={iconStyles}>
            {iconLeft ? <Icon id={iconId} /> : null}
            {children}
            {!iconLeft ? <Icon id={iconId} /> : null}
          </ChildrenContainer>
        ) : (
          children
        )}
      </Component>
    );
  }
};

interface ButtonProps {
  fullWidth?: boolean;
}

const ButtonBase = styled.button<ButtonProps>`
  min-height: var(--height);
  height: var(--height);
  font-size: var(--font-size);
  padding: var(--padding);
  box-shadow: var(--box-shadow);

  font-family: 'Epilogue';
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--letter-spacing-button);
  width: ${(p) => (p.fullWidth ? '100%' : 'fit-content')};
  border-radius: 999px;
  border: none;
  cursor: pointer;
  transition: all 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  @media ${(p) => p.theme.queries.laptopAndDown} {
    min-height: var(--laptop-height, var(--height));
    height: var(--laptop-height, var(--height));
    font-size: var(--laptop-font-size, var(--font-size));
    padding: var(--laptop-padding, var(--padding));
    box-shadow: var(--laptop-box-shadow, var(--box-shadow));
  }

  @media ${(p) => p.theme.queries.tabletAndDown} {
    min-height: var(--tablet-height, var(--laptop-height, var(--height)));
    height: var(--tablet-height, var(--laptop-height, var(--height)));
    font-size: var(
      --tablet-font-size,
      var(--laptop-font-size, var(--font-size))
    );
    padding: var(--tablet-padding, var(--laptop-padding, var(--padding)));
    box-shadow: var(
      --tablet-box-shadow,
      var(--laptop-box-shadow, var(--box-shadow))
    );
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    min-height: var(
      --phone-height,
      var(--tablet-height, var(--laptop-height, var(--height)))
    );
    height: var(
      --phone-height,
      var(--tablet-height, var(--laptop-height, var(--height)))
    );
    font-size: var(
      --phone-font-size,
      var(--tablet-font-size, var(--laptop-font-size, var(--font-size)))
    );
    padding: var(
      --phone-padding,
      var(--tablet-padding, var(--laptop-padding, var(--padding)))
    );
    box-shadow: var(
      --phone-box-shadow,
      var(--tablet-box-shadow, var(--laptop-box-shadow, var(--box-shadow)))
    );
  }
`;

const FillButton = styled(ButtonBase)`
  background-color: var(--color-secondary-500);
  color: var(--color-white);

  &:hover:enabled {
    background-color: var(--color-secondary-700);
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const OutlineButton = styled(ButtonBase)`
  background-color: var(--color-white);
  color: var(--color-secondary-500);

  &:hover:enabled {
    color: var(--color-secondary-700);
    background-color: var(--color-gray-100);
  }

  &:disabled {
    color: var(--color-gray-300);
  }
`;

const GhostButton = styled(OutlineButton)`
  box-shadow: none !important;
  background-color: transparent;

  &:hover:enabled {
    background-color: transparent;
  }
`;

const GhostWhiteButton = styled(GhostButton)`
  color: var(--color-white);

  &:hover:enabled {
    color: var(--color-off-white-500);
  }

  &:disabled {
    color: var(--color-gray-300);
  }
`;

const ChildrenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  letter-spacing: inherit;

  & > svg {
    width: var(--icon-size);
    height: var(--icon-size);

    @media ${(p) => p.theme.queries.laptopAndDown} {
      width: var(--laptop-icon-size, var(--icon-size));
      height: var(--laptop-icon-size, var(--icon-size));
    }

    @media ${(p) => p.theme.queries.tabletAndDown} {
      width: var(--tablet-icon-size, var(--laptop-icon-size, var(--icon-size)));
      height: var(
        --tablet-icon-size,
        var(--laptop-icon-size, var(--icon-size))
      );
    }

    @media ${(p) => p.theme.queries.phoneAndDown} {
      width: var(
        --phone-icon-size,
        var(--tablet-icon-size, var(--laptop-icon-size, var(--icon-size)))
      );
      height: var(
        --phone-icon-size,
        var(--tablet-icon-size, var(--laptop-icon-size, var(--icon-size)))
      );
    }
  }
`;

const Anchor = styled.a`
  text-decoration: none;
`;

export default Button;
